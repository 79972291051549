import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
* @typedef {Object} CustomerData
* @property {String} landingApplicationId
* @property {String} gender
* @property {String} firstName
* @property {String} middleName
* @property {String} lastName
* @property {String} mothersName
* @property {Number} birthDay
* @property {Number} birthMonth
* @property {Number} birthYear
* @property {String} birthState
* @property {String} birthCountry
* @property {String} mobileNumber
* @property {String} email - user@example.com
* @property {String} streetName
* @property {String} exteriorNumber
* @property {String} interiorNumber
* @property {String} neighborhood
* @property {String} postalCode
*/

/**
 * @class
 * @param  {String} landingApplicationId
 */
export class CustomerServiceAfi extends Service {
  constructor(landingApplicationId) {
    super()
    this._landingApplicationId = `${landingApplicationId}`
    this._gender = ''
    this._firstName = ''
    this._middleName = ''
    this._lastName = ''
    this._mothersName = ''
    this._birthDay = 0
    this._birthMonth = 0
    this._birthYear = 0
    this._birthState = ''
    this._birthCountry = ''
    this._mobileNumber = ''
    this._email = ''
    this._streetName = ''
    this._exteriorNumber = ''
    this._interiorNumber = ''
    this._neighborhood = ''
    this._postalCode = ''    
    this._chatOptIn = ''
    this._clickId = ''
    this._prospectoId= ''
    this._numero_ssn=''
  }


  /**
   * @param  {String} chat - true | false
   */
  setChat(chat) {
    this._chatOptIn = chat
  }

  setClickId(clickId) {
    this._clickId = clickId
  }
  setProspectoId(prospectoId){
    this._prospectoId = prospectoId
  }

  setNumeroSsn(numero_ssn){
    this._numero_ssn = numero_ssn
  }

  /**
   * @param  {String} gender - M | F
   */
  setGender(gender) {
    this._gender = `${gender.toUpperCase()}`
  }

  /**
   * @param  {String} firstName
   * @param  {String} middleName
   * @param  {String} lastName
   * @param  {String} mothersName
   */
  setFullName(firstName, middleName, lastName, mothersName) {
    this._firstName = `${firstName}`
    this._middleName = `${middleName}`
    this._lastName = `${lastName}`
    this._mothersName = `${mothersName}`
  }

  /**
   * @param  {Date} birthDate
   */
  setBirthDate(dia, mes, anio) {
    this._birthDay = dia
    this._birthMonth = mes
    this._birthYear = anio
  }
  // setBirthDate(birthDate) {
  //   this._birthDay = birthDate.getDate()
  //   this._birthMonth = birthDate.getMonth() + 1
  //   this._birthYear = birthDate.getFullYear()
  // }


  /**
   * @param  {String} birthCountry
   * @param  {String} birthState
   */
  setBirthLocation(birthCountry, birthState) {
    this._birthCountry = `${birthCountry}`
    this._birthState = `${birthState}`
  }

  /**
   * @param  {String} email
   */
  setEmail(email) {
    this._email = `${email}`
  }

  /**
   * @param  {String} mobileNumber
   */
  setMobileNumber(mobileNumber) {
    this._mobileNumber = `${mobileNumber}`
  }  

  /**
   * @param  {String} streetName
   * @param  {String} interiorNumber
   * @param  {String} exteriorNumber
   * @param  {String} neighborhood
   * @param  {String} postalCode
   */
  setAddress(streetName, interiorNumber, exteriorNumber, neighborhood, postalCode) {
    this._streetName = `${streetName}`
    this._exteriorNumber = `${interiorNumber}`
    this._interiorNumber = `${exteriorNumber}`
    this._neighborhood = `${neighborhood}`
    this._postalCode = `${postalCode}`
  }

  /**
   * @returns {CustomerData}
   */
  get data() {
    return {
      creditApplicationId: `${this._landingApplicationId}`,      
      firstName: `${this._firstName}`,
      gender: `${this._gender}`,      
      lastName: `${this._lastName}`,
      mothersName: `${this._mothersName}`,
      birthDay: this._birthDay + 0,
      birthMonth: this._birthMonth + 0,
      birthYear: this._birthYear + 0,
      birthState: `${this._birthState}`,
      birthCountry: `${this._birthCountry}`,
      mobileNumber: `${this._mobileNumber}`,
      email: `${this._email}`,
      streetName: `${this._streetName}`,
      exteriorNumber: `${this._exteriorNumber}`,
      interiorNumber: `${this._interiorNumber}`,
      neighborhood: `${this._neighborhood}`,
      postalCode: `${this._postalCode}`,      
      chatOptIn: this._chatOptIn,
      clickId: `${this._clickId}`,
      prospectoId: this._prospectoId,
      ssn: `${this._numero_ssn}`,
      

    }
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'PUT',
      url: '/customer',
      data: self.data
    }))
  }
}
