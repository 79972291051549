/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import { Grid, Select, FormControl, FormHelperText, InputLabel, FormLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withThemeProps } from '@findep/microfronts-core'
import { format, isValid, parse } from 'date-fns'
function DatePicker(props) {
  const formControl = css`
    margin: theme.spacing(0.5);
    margin-right: 0.5rem !important;
  `

  const DAYS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ]
  const YESTERDAY = new Date()

  const [obj, setObj] = useState([])
  const [years, setYears] = useState([])
  const [state, setState] = useState({day: null, month: null, year: null})
  const [error, setError] = useState()
  const [onChangeValue, setOnChangeValue] = useState(true)

  const refState = useRef({...state})

  useEffect(() => {
    async function mount() {
      await newArrayYears()
      refState.current = {...state}
    }
    mount()
  }, [])

  useEffect(() => {
    orderFormatDay()
  }, [props.formato, years])

  useEffect(() => {
      if(props.value instanceof Date &&props.value.day !== state.day && props.value.month !== state.month && props.value.year !== state.year){
        console.log('tipe date');
        setState({day: props.value.getDate(), month: props.value.getMonth(), year: props.value.getFullYear()})
      }else if(props.value instanceof Object &&props.value.day !== state.day && props.value.month !== state.month && props.value.year !== state.year){
        console.log('tupe object');
        setState({day: props.value.day, month: props.value.month, year: props.value.year})
      }
      setOnChangeValue(false)
  }, [props.value])

  useEffect(() => {
    orderFormatDay()
  }, [years])

  useEffect(() => {
    newArrayYears()
  }, [props.aniosMaximos, props.aniosMinimos])

  useEffect(() => {
    if(JSON.stringify(refState.current) !== JSON.stringify(state)){
      if (state.day && state.month && state.year ) {
        evaluation()
        refState.current = {...state}
      }
    }
  }, [state])

  const orderFormatDay = () => {
    switch (props.formato) {
      case 'DD/MM/AAAA':
        setObj([
          {
            name: 'day',
            label: props.dayLabel,
            values: DAYS
          },
          {
            name: 'month',
            label: props.monthLabel,
            values: props.months
          },
          {
            name: 'year',
            label: props.yearLabel,
            values: years
          }
        ])
        break
      case 'MM/DD/AAAA':
        setObj([
          {
            name: 'month',
            label: props.monthLabel,
            values: props.months
          },
          {
            name: 'day',
            label: props.dayLabel,
            values: DAYS
          },
          {
            name: 'year',
            label: props.yearLabel,
            values: years
          }
        ])
        break
      case 'AAAA/MM/DD':
        setObj([
          {
            name: 'year',
            label: props.yearLabel,
            values: years
          },
          {
            name: 'month',
            label: props.monthLabel,
            values: props.months
          },
          {
            name: 'day',
            label: props.dayLabel,
            values: DAYS
          }
        ])
        break
    }
  }

  const newArrayYears = () => {
    let years = []

    let LAST_YEAR = new Date()
    LAST_YEAR.setFullYear(YESTERDAY.getFullYear() - props.aniosMinimos)

    let FIRST_YEAR = new Date()
    FIRST_YEAR.setFullYear(YESTERDAY.getFullYear() - props.aniosMaximos)
    FIRST_YEAR.setMonth(YESTERDAY.getMonth() - props.mesesMaximos)

    for (let i = LAST_YEAR.getFullYear(); i >= FIRST_YEAR.getFullYear(); i--) {
      years = [...years, i]
    }
    setYears(years)
  }

  const evaluation = async() => {
    const dateEntered = parse(`${parseInt(state.year)}-${parseInt(state.month)}-${parseInt(state.day)}`, 'yyyy-MM-dd', new Date())
    
    // EL MES QUE INTRODUJERON ES IGUAL AL MES QUE ARROJA LA FORMACION DE LA NUEVA FECHA?
    if (isValid(dateEntered)) {
      /*  La fecha es valida, aqui se evalua la edad */

      let LAST_YEAR = new Date()
      LAST_YEAR.setFullYear(YESTERDAY.getFullYear() - props.aniosMinimos)

      let FIRST_YEAR = new Date()
      FIRST_YEAR.setFullYear(YESTERDAY.getFullYear() - props.aniosMaximos)
      FIRST_YEAR.setMonth(YESTERDAY.getMonth() - props.mesesMaximos)

      if (dateEntered > LAST_YEAR) {
        /* 
        * La fecha es incorrecta porque no alcanza la edad minima
        */
        if(onChangeValue){
          /* 
          * El onChange solo se ejecuta cuando el usuario cambia algun select y no cuando la fecha viene de la prop value
          *onChangeValue cambia a true en la funcion handleChange y a false en el useEffect de la prop.value
          */
          props.onChange(format(dateEntered, 'dd.MM.yyyy'), {day:dateEntered.getDate(),month:dateEntered.getMonth() + 1,year:dateEntered.getFullYear()})
        }
        props.onError({errorType: 'La fecha no alcanza la edad minima'})
        setError(props.helperText ? props.helperText : props.errorMessage)
        return 1

      } else if (dateEntered < FIRST_YEAR) {
         /* 
        * La fecha es incorrecta porque supera la edad maxima
        */
         if(onChangeValue){
          /* 
          * El onChange solo se ejecuta cuando el usuario cambia algun select y no cuando la fecha viene de la prop value
          *onChangeValue cambia a true en la funcion handleChange y a false en el useEffect de la prop.value
          */
          props.onChange(format(dateEntered, 'dd.MM.yyyy'), {day:dateEntered.getDate(),month:dateEntered.getMonth() + 1,year:dateEntered.getFullYear()})
        }
        props.onError({errorType: 'La fecha supera la edad maxima'})
        setError(props.helperText ? props.helperText : props.errorMessage)
        return -1

      }
      // Está bien la fecha
      if(onChangeValue){
        /* 
        * El onChange solo se ejecuta cuando el usuario cambia algun select y no cuando la fecha viene de la prop value
        *onChangeValue cambia a true en la funcion handleChange y a false en el useEffect de la prop.value
        */
        props.onChange(format(dateEntered, 'dd.MM.yyyy'), {day:dateEntered.getDate(),month:dateEntered.getMonth() + 1,year:dateEntered.getFullYear()})
      }
      setError('')

      return 0 // Está bien la fecha

      /*
       */
    } else {
      /* 
      * La fecha Es invalida o no existe como el 31 de febrero de cualquier año
      */
      if(onChangeValue){
        /* 
        * El onChange solo se ejecuta cuando el usuario cambia algun select y no cuando la fecha viene de la prop value
        *onChangeValue cambia a true en la funcion handleChange y a false en el useEffect de la prop.value
        */
        props.onChange('', {day:state.day,month: state.month,year: state.year})
      }
      props.onError({errorType:'La fecha no es valida'})
      setError(props.errorMessage)
    }
  }

  const handleChange = async (event) => {
    setOnChangeValue(true)
    setError('')
    const name = event.target.name
    await setState({
      ...state,
      [name]: Number(event.target.value)
    })
  }

  return (
    <Grid container spacing={2}>
      {props.title !== '' && (
        <Grid item xs={12}>
          <FormControl style={{ paddingLeft: 5, marginBottom: 10 }}>
            <FormLabel component='legend'>{props.title}</FormLabel>
          </FormControl>
        </Grid>
      )}
      
        {obj?.map((item, key) => {
          return (
            <Grid item xs={4}>
            <FormControl variant='outlined' css={formControl} key={key + 1} fullWidth>
              <InputLabel htmlFor='outlined-age-native-simple' key={key + 2}>
                {item.label}
              </InputLabel>
              <Select
                key={key}
                disabled={props.disabled}
                autoWidth
                native
                value={props.value[item.name] ? props.value[item.name] : (state[item.name] || '') }
                onChange={handleChange}
                label={`${item.label}`}
                inputProps={{
                  name: `${item.name}`,
                  id: `outlined-age-native-simple-${item.label}`
                }}
              >
                <option key='nonedia'  value='' disabled />
                {item?.values?.map((row, index) => {
                  return (
                    <option key={index + 1} value={item.name === 'month' ? (index + 1) : row}>
                      {row}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
            </Grid>
          )
        })}
      
      <Grid item xs={12}>
        <FormControl error={error}>
          <FormHelperText>{error === '' ? '' : error}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  )
}

DatePicker.propTypes = {
  helperText: PropTypes.string,
  title: PropTypes.string,
  formato: PropTypes.oneOf(['DD/MM/AAAA', 'MM/DD/AAAA', 'AAAA/MM/DD']),
  aniosMaximos: PropTypes.number,
  aniosMinimos: PropTypes.number,
  mesesMaximos: PropTypes.number,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  value:  PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Date)
  ])
}

DatePicker.defaultProps = {
  aniosMaximos: 90,
  mesesMaximos: 11,
  aniosMinimos: 18,
  title: 'Fecha de nacimiento',
  formato: 'DD/MM/AAAA',
  onError: () => {},
  months: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
  monthLabel: 'Mes',
  dayLabel: 'Día',
  yearLabel: 'Año',
  errorMessage: 'La fecha introducida es invalida',
  value: {},
  onChange: () => {}
}

export default withThemeProps(DatePicker, 'MFSelectDatePicker')