import { Component } from "react"
import PropTypes from 'prop-types'
import { css } from '@emotion/react'

// FINANCIERA COMPONENTS
import { CardElevation, MaterialInput } from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS 
import { Grid, Box } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';

//ICONS MATERIAL UI
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import HomeIcon from '@material-ui/icons/Home';
import { withThemeProps } from "@findep/microfronts-core"
import { iconStyle, h1Style, root } from '../../../styles/lo-sentimos'



const cmpTxt2 = css` 
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
 `


class AddressAfi extends Component {
    render() {
        const { 
            titleIcon,
            typographySubtitle,
            inputLabelAddress1,
            inputLabelAddress2,
            inputLabelCp,
            inputErrorMessageCP,
            inputLabelEstado
        } = this.props

        return (
            <CardElevation>
                <div css={root}>
                    <Grid container justify="center">
                        <Grid item style={{ textAlign: "center" }}>
                            <div css={root}>
                                <LocationOnIcon css={iconStyle} />
                                <Typography css={h1Style} component='span'>
                                    {titleIcon}
                                </Typography>      
                            </div>
                        </Grid>
                    </Grid>
                    <Box component="span" m={1} style={{ textAlign: "center" }}>
                        <Typography component='h1' css={cmpTxt2}>
                            {typographySubtitle}
                        </Typography>
                    </Box>

                    <Grid container spacing={3}>

                        <Grid item xs={1}>
                            <HomeIcon />
                        </Grid>
                        <Grid item xs={11}>
                            <MaterialInput
                                id={`${this.props.pageId}-direccion1`}
                                name={`${this.props.pageId}-input`}
                                type="text"
                                label={inputLabelAddress1}
                                value={this.props.state.direccion1}
                                pattern="[A-ZÑ][0-9]{2,}( [A-ZÑ0-9]{2})?"
                                accept="A-ZÑ [0-9] "
                                transform={(value) => value.toUpperCase().slice(0, 27)}
                                onChange={(_event, component) => this.props.onChange('direccion1', component)}
                                disabled={this.props.activeCP}
                                inputmode="true"                                            
                                inputProps={ { 'aria-label': inputLabelAddress1, "aria-invalid": "true" }}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <HomeIcon />
                        </Grid>
                        <Grid item xs={11}>
                            <MaterialInput
                                type="text"
                                id={`${this.props.pageId}-direccion2`}
                                name={`${this.props.pageId}-direccion2-input`}
                                label={inputLabelAddress2}
                                value={this.props.state.direccion2}
                                pattern="[A-ZÑ][0-9]{2,}( [A-ZÑ0-9]{2})?"
                                accept="A-ZÑ [0-9] "
                                transform={(value) => value.toUpperCase().slice(0, 8)}
                                onChange={(_event, component) => this.props.onChange('direccion2', component)}
                                disabled={this.props.state._errors.direccion1.error}
                                inputmode="true"                                            
                                inputProps={ { 'aria-label': inputLabelAddress2, "aria-invalid": "true" }}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <AddLocationIcon />
                        </Grid>
                        <Grid item xs={11}>
                            <MaterialInput
                                id={`${this.props.pageId}-codigo-postal`}
                                name={`${this.props.pageId}-codigo-postal-input`}
                                type="text"
                                inputmode="numeric"
                                autocomplete="postal-code"
                                label={inputLabelCp}
                                value={this.props.state.cp}
                                pattern="[0-9]{5}"
                                accept="0-9"
                                transform={(value) => value.slice(0, 5)}
                                error={this.props.state._errors.cp.error}
                                onChange={(_event, component) => this.props.onChangeCp(component)}
                                required
                                errorMessage={inputErrorMessageCP}
                                disabled={this.props.state._errors.direccion1.error}                                                                    
                                inputProps={ { 'aria-label': inputLabelCp, "aria-invalid": "true" }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <AddLocationIcon />
                        </Grid>
                        <Grid item xs={11}>
                            <MaterialInput
                                type="text"
                                id={`${this.props.pageId}-estado`}
                                name={`${this.props.pageId}-estado-input`}
                                label={inputLabelEstado}
                                value={this.props.state.estado}
                                accept="A-Z0-9,.\/\\ "
                                transform={(value) => value.toUpperCase().slice(0, 10)}
                                onChange={(_event, component) => this.props.onChange('estado', component)}
                                required={true}
                                disabled={true}
                                inputmode="true"                                            
                                inputProps={ { 'aria-label': inputLabelEstado, "aria-invalid": "true" }}
                            />
                        </Grid>

                    </Grid>

                </div>
            </CardElevation>
        )
    }
}

export default withThemeProps(AddressAfi, 'VDNPAddressAfi')

AddressAfi.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeCp: PropTypes.func.isRequired,
    submitStepTwo: PropTypes.func,
    state: PropTypes.object.isRequired,
    activeCP: PropTypes.any.isRequired
}

AddressAfi.defaultProps = {
    titleIcon: "Datos del domicilio", 
    typographySubtitle: "Proporciona los datos de tu vivienda.", 
    inputLabelAddress1: 'Dirección 1', 
    inputLabelAddress2: 'Dirección 2', 
    inputLabelCp: "Código postal", 
    inputErrorMessageCP: "se aceptan solo 5 dígitos", 
    inputLabelEstado: "Estado"
}
