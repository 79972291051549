import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import TusDatos from '../components/pages/afi/TusDatos'
import AttachMoney from '@material-ui/icons/AttachMoney';
import Home from '@material-ui/icons/Home';
import Check from '@material-ui/icons/Check';
import HowToRegIcon from '@material-ui/icons/HowToReg';

const iconos = { 1: <Home />, 2: <HowToRegIcon />, 3: <Check />, 4: <AttachMoney /> }
// const texts = [{ texto: 'A' }, { texto: 'B' }, { texto: 'C' }, { texto: 'D' }]
// const currentStep = 1

const TusDatosPage = ({ pageContext, location }) => (

  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    location={location}
    pageContext={pageContext}
    gradient
    currentStep={pageContext.step}
    iconos={iconos}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <TusDatos pageContext={pageContext} />
      </>
    )}
  />
)

export default TusDatosPage